import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import Fade from 'react-reveal/Fade'
import { Global, css } from '@emotion/core'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import { IContentfulPolicyPage } from '../interfaces'
import Theme, { Mq } from '../themes/default'
import Layout from '../organisms/layout'
import { SubpageHero } from '../molecules/subpage-hero'
import SEO from '../organisms/seo'

const ContentStyles = css`
  h1 {
    font-size: 1.8rem;
    margin-bottom: 1rem;

    ${Mq.md} {
      font-size: 2.625rem;
    }
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;

    ${Mq.md} {
      font-size: 2rem;
    }
  }

  h3 {
    font-size: 1.3rem;
    margin-bottom: 1rem;

    ${Mq.md} {
      font-size: 1.5rem;
    }
  }

  h4 {
    font-size: 1.1rem;
    margin-bottom: 1rem;

    ${Mq.md} {
      font-size: 1.3rem;
    }
  }

  p {
    font-size: 0.9375rem;
    line-height: 1.5625rem;
    margin-bottom: 1.5rem;

    ${Mq.md} {
      margin-bottom: 2rem;
      font-size: 1.0625rem;
      line-height: 1.6875rem;
    }
  }

  blockquote p {
    background: ${Theme.colors.lightBrown};
    font-family: ${Theme.fonts.primary};
    padding: 1rem;
    font-size: 2rem;
    line-height: 2.5rem;
    font-style: italic;
    margin-top: 1rem;
    margin-bottom: 2rem;

    ${Mq.md} {
      padding: 2rem;
    }
  }

  ul {
    list-style-type: circle;
  }

  ul,
  ol {
    margin-top: 1rem;
    margin-bottom: 2rem;
    padding-left: 2rem;
    font-size: 0.9375rem;
    line-height: 1.5625rem;

    ${Mq.md} {
      font-size: 1.0625rem;
      line-height: 1.6875rem;
    }

    li {
      margin-bottom: 1rem;
    }
  }

  a {
    color: ${Theme.colors.primary};
  }

  img {
    margin-bottom: 1.5rem;

    ${Mq.md} {
      margin-bottom: 2rem;
    }
  }
`

const PrivacyPolicyContainer = styled.div`
  padding: 1rem 5%;

  ${Mq.md} {
    padding: 3rem 3% 7rem;
    margin: 0 5%;
  }
  ${Mq.xxl} {
    margin: 0 15%;
  }
`

type PrivacyPolicyPageQuery = {
  contentfulPrivacyPolicyPage: IContentfulPolicyPage
}

const PrivacyPolicyPage = () => {
  const {
    contentfulPrivacyPolicyPage,
  }: PrivacyPolicyPageQuery = useStaticQuery(
    graphql`
      query PrivacyPolicyPageQuery {
        contentfulPrivacyPolicyPage {
          pageTitle
          pageDescription
          title
          content {
            json
          }
        }
      }
    `,
  )

  return (
    <Layout hideNavItems>
      <Global styles={ContentStyles} />
      <SEO description={contentfulPrivacyPolicyPage.pageDescription} title={contentfulPrivacyPolicyPage.pageTitle} />
      <SubpageHero title={contentfulPrivacyPolicyPage.title} />
      <Fade bottom={true} cascade={true} distance={Theme.fadeDistance}>
        <PrivacyPolicyContainer>
          {documentToReactComponents(contentfulPrivacyPolicyPage.content.json)}
        </PrivacyPolicyContainer>
      </Fade>
    </Layout>
  )
}

export default PrivacyPolicyPage
